.App {
  text-align: center;
}

a {
  color: #506bff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ok-btn {
  background-color: #8bb973 !important;
}

.alert-btn {
  background-color: #e1a1a1 !important;
}

.anchor-top {
  scroll-margin-top: 80px;
}

#custom-switch {
  cursor: pointer;
}
.form-check-label {
  cursor: pointer;
}

.imglist {
  user-select: none;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 5px;
  width: auto !important;
  padding-left: 1px !important;
}

@media (max-width: 1400px) { /* Puedes ajustar los px según sea necesario */
  .imglist {
    grid-template-columns: auto auto;
  }
}

.guide-nav-container {
  display: block;
}

@media (max-width: 768px) {
  .guide-nav-container {
    display: none;
  }
}

@media (max-width: 1000px) {
  .imgarea {
    margin-top: 15px !important;
  }
}

.guide-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  height: 90vh;
  overflow: visible auto;
}

.guide-nav-project {
  font-weight: bold;
  list-style-type: none;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 6px;
  padding-top:  6px;
}

.guide-nav-project:hover {
  background-color: #efefef;
}

.guide-nav-guide {
  font-weight: bold;
  margin-left: 20px;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 6px;
  padding-top:  6px;
  font-size: 0.9rem;
}

.guide-nav-guide-other {
  font-weight: normal;
}

.guide-nav-guide a {
  text-decoration: none;
}

.guide-nav-guide:hover {
  background-color: #efefef;
}

.guide-nav-step {
  margin-left: 30px;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 6px;
  padding-top:  6px;
  list-style-type: circle;
  list-style-type: none;
  font-size: 0.9rem;
}

.guide-nav-step a {
  text-decoration: none;
}

.guide-nav-step:hover {
  background-color: #efefef;
}

.active-nav {
  font-weight: bold;
}

.imgitem {
  justify-content: center;
  align-items: center;
  color: black;
  max-height: 145px;
  cursor: grab;
  user-select: none;
  width: 158px;

}

.copyText {
  cursor: copy;
  background-color: #e7e5e5;
  border-radius: 4px;
  padding: 4px;
  display: block;
  border: 1px solid #c3c3c3;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.super-divider {
    width: 100%;
    height: 1rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.navi {
  margin-left: 6px;
}

.guides-img {
  width: 286px;
  height: 180px;
  object-fit: cover;
}

.projects-img {
  width: 2500px;
  height: 280px;
  object-fit: cover;
}

.border-tumb {
  border: 1px solid;
}

.tui-image-editor-header-logo {
  display: none;
}

.tui-image-editor-header-buttons {
  display: none;
}

.step-items {
  list-style: none;
}

li.step-items {
  margin-bottom: 8px;
}

.step-text {
  color: black;
  padding-left: 27px;
  display: block;
}

.guide-prop {
  font-weight:  700;
}

.difficulty-very-easy {
  font-weight:  700;
  color: #159a3b;
}

.difficulty-easy {
  font-weight:  700;
  color: #70c30d;
}

.difficulty-moderate {
  font-weight:  700;
  color: #d3c436;
}

.difficulty-hard {
  font-weight:  700;
  color: #da7600;
}

.difficulty-very-hard {
  font-weight:  700;
  color: #c61e1e;
}

.step-text-info {
  color: black;
  padding-left: 27px;
  display: block;
}

.step-text-red {
  color: #ff4040;
  padding-left: 27px;
  display: block;
}

.bullet-blue {
  color: #515ce6;
  float: left;
  padding-top: 4px;
}

.bullet-yellow {
  color: #feff00;
  float: left;
  padding-top: 4px;
}

.bullet-red {
  color: #ff4040;
  float: left;
  padding-top: 4px;
}

.bullet-black {
  color: black;
  float: left;
  padding-top: 4px;
}

.bullet-info {
  color: black;
  float: left;
  padding-top: 4px;
}

.bullet-green {
  color: #03bd9e;
  float: left;
  padding-top: 4px;
}

.bullet-pink {
  color: #ff5583;
  float: left;
  padding-top: 4px;
}

.bullet-orange {
  color: #ffbb3b;
  float: left;
  padding-top: 4px;
}

.bullet-lightblue {
  color: #00a9ff;
  float: left;
  padding-top: 4px;
}

.bullet-violet {
  color: #9e5fff;
  float: left;
  padding-top: 4px;
}

.bullet-dynamic {
  float: left;
  padding-top: 4px;
}

.tui-image-editor-button-arrow {
    position: relative;
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    font-size: 11px;
    margin: 0 9px 0 9px;
}

.sort-grab-guides {
    display: block;
    cursor: grab;
    position: absolute;
    padding: 3px 6px 3px 6px;
    background-color: white;
    margin: 0px;
    border-radius: 4px;
    border: 1px solid #5c5c79;
}

.sort-grab-thumb {
    display: block;
    cursor: grab;
    position: absolute;
    padding: 1px 3px 0px 3px;
    background-color: white;
    margin: 0px;
    border-radius: 4px;
    border: 1px solid #5c5c79;
}


.hr-with-button-top {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 15px;
}


.hr-with-button-top::before,
.hr-with-button-top::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
}
.hr-with-button-top::before {
    margin-right: 10px;
}
.hr-with-button-top::after {
    margin-left: 10px;
}

.styled-hr-top {
    height: 11px; /* Set to the height of your button, adjust as needed */
    border: 0;
    border-bottom: 1px solid #000;
    line-height: 38px; /* Set to the height of your button, adjust as needed */
    margin: 0 10px; /* Match the margins used around the button */
    width: 100%;
    margin-bottom: 20px;
}





.hr-with-button {
    display: flex;
    align-items: center;
    text-align: center;
    height: 48px;
}


.hr-with-button::before,
.hr-with-button::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
}
.hr-with-button::before {
    margin-right: 10px;
}
.hr-with-button::after {
    margin-left: 10px;
}

.styled-hr {
    height: 48px; /* Set to the height of your button, adjust as needed */
    border: 0;
    border-top: 1px solid #000;
    line-height: 38px; /* Set to the height of your button, adjust as needed */
    margin: 0 10px 0px 0px; /* Match the margins used around the button */
    width: 100%;
}

